import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Nb_Cultivation_Plan_Insert_Input } from '@/generated/graphql'
import dayjs from 'dayjs'
import { LocationHistoryResult, LocationHistoryVariables } from 'hooks/layers/useCreateUserLocationHistoryLayer'

export interface CultivationPlanCopyModeProperty {
    isRunning: boolean
    source: Nb_Cultivation_Plan_Insert_Input | null
    destinations: {
        userFieldId: string
        userFieldName: string
    }[]
}

export type SensingType = {
    key: string,
    name: string,
    colorMap: SensingColor[],
    rangeSliderLabelList: number[],
}

type SensingColor = {
    color: string,
    index: number,
}

type SensingVariables = {
    dateString: string,
    sensingType: SensingType | null,
}

export interface SensingDisplayProperty {
    rangeSliderValues: number[],
    isMarking: boolean,
}

export type SensingResult = {
    userFieldIds: string[],
}

type TemperatureVariables = {
    datetimeString: string,
    temperatureTypeKey: string,
}

export type SensingDisplayMarker = {
    lat: number
    lng: number
    value: any
}

export interface LayerState {
    cultivationPlanCopyModeProperty: CultivationPlanCopyModeProperty,
    isShowUserFieldNameOnTheMap: boolean
    isDisplayWideOrthoimgUserFieldLayer: boolean
    locationHistoryVariables: LocationHistoryVariables
    locationHistoryResult: LocationHistoryResult
    selectedLocationHistoryContextIds: string[]
    disabledLocationHistoryFitBounds: boolean,
    sensingVariables: SensingVariables,
    sensingDisplayMarkers: SensingDisplayMarker[],
    sensingDisplayProperty: SensingDisplayProperty
    sensingResult: SensingResult | null
    temperatureVariables: TemperatureVariables
}

export const defaultLayerState: LayerState = {
    cultivationPlanCopyModeProperty: {
        isRunning: false,
        source: null,
        destinations: []
    },
    isShowUserFieldNameOnTheMap: false,
    isDisplayWideOrthoimgUserFieldLayer: false,
    locationHistoryVariables: {
        from: dayjs().add(-3, "day").format("YYYY/MM/DD"),
        to: dayjs().format("YYYY/MM/DD"),
        taskList: [],
        userList: [],
        analysisContextIds: [],
    },
    locationHistoryResult: { loading: false, locationHistories: [] },
    selectedLocationHistoryContextIds: [],
    disabledLocationHistoryFitBounds: false,
    sensingVariables: {
        dateString: dayjs().format("YYYY/MM/DD"),
        sensingType: null,
    },
    sensingDisplayMarkers: [],
    sensingDisplayProperty: {
        rangeSliderValues: [0, 255],
        isMarking: false,
    },
    sensingResult: null,
    temperatureVariables: {
        datetimeString: dayjs().format("YYYY/MM/DD HH:00:00"),
        temperatureTypeKey: "",
    }
}

const layerStateSlice = createSlice({
    name: "layer-state",
    initialState: defaultLayerState,
    reducers: {
        initializeLayerState: (state: LayerState) => {
            state.cultivationPlanCopyModeProperty = defaultLayerState.cultivationPlanCopyModeProperty
            state.sensingDisplayProperty = defaultLayerState.sensingDisplayProperty
            state.isShowUserFieldNameOnTheMap = defaultLayerState.isShowUserFieldNameOnTheMap
            state.isDisplayWideOrthoimgUserFieldLayer = defaultLayerState.isDisplayWideOrthoimgUserFieldLayer
            state.sensingVariables = defaultLayerState.sensingVariables
            state.sensingDisplayMarkers = defaultLayerState.sensingDisplayMarkers
        },
        setCultivationPlanCopyModeProperty: (state: LayerState, action: PayloadAction<CultivationPlanCopyModeProperty>) => {
            state.cultivationPlanCopyModeProperty = action.payload
        },
        setIsShowUserFieldNameOnTheMap: (state: LayerState, action: PayloadAction<boolean>) => {
            state.isShowUserFieldNameOnTheMap = action.payload
        },
        setIsDisplayWideOrthoimgUserFieldLayer: (state: LayerState, action: PayloadAction<boolean>) => {
            state.isDisplayWideOrthoimgUserFieldLayer = action.payload
        },
        setLocationHistoryVariables: (state: LayerState, action: PayloadAction<LocationHistoryVariables>) => {
            state.locationHistoryVariables = action.payload
        },
        setLocationHistoryResult: (state: LayerState, action: PayloadAction<LocationHistoryResult>) => {
            state.locationHistoryResult = action.payload
        },
        setSelectedLocationHistoryContextIds: (state: LayerState, action: PayloadAction<{locationHistoryContextIds: string[], disabledFitBounds?: boolean}>) => {
            state.selectedLocationHistoryContextIds = action.payload.locationHistoryContextIds
            state.disabledLocationHistoryFitBounds = action.payload.disabledFitBounds ?? false
        },
        setSensingVariables: (state: LayerState, action: PayloadAction<SensingVariables>) => {
            state.sensingVariables = action.payload
        },
        setSensingDisplayMarkers: (state: LayerState, action: PayloadAction<SensingDisplayMarker[]>) => {
            state.sensingDisplayMarkers = action.payload
        },
        setSensingDisplayProperty: (state: LayerState, action: PayloadAction<SensingDisplayProperty>) => {
            state.sensingDisplayProperty = action.payload
        },
        setSensingResult: (state: LayerState, action: PayloadAction<SensingResult | null>) => {
            state.sensingResult = action.payload
        },
        setTemperatureVariables: (state: LayerState, action: PayloadAction<TemperatureVariables>) => {
            state.temperatureVariables = action.payload
        },
    }
})

export const {
    initializeLayerState,
    setCultivationPlanCopyModeProperty,
    setIsShowUserFieldNameOnTheMap,
    setIsDisplayWideOrthoimgUserFieldLayer,
    setLocationHistoryVariables,
    setLocationHistoryResult,
    setSelectedLocationHistoryContextIds,
    setSensingVariables,
    setSensingDisplayMarkers,
    setSensingDisplayProperty,
    setSensingResult,
    setTemperatureVariables,
} = layerStateSlice.actions

export const layerStateReducer = layerStateSlice.reducer
